import React from 'react';
import ThemeProviderWithToggle from './ThemeProviderWithToggle';
import BackgroundWrapper from './BackgroundWrapper';

const App = () => {

  return (
    <ThemeProviderWithToggle>
      <BackgroundWrapper />
    </ThemeProviderWithToggle>
  );
};

export default App;
