import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Box, Card, CardContent, Link } from '@mui/material';
import { LoadScript } from '@react-google-maps/api';
import Body from './Body';
import GoogleMapComponent from './GoogleMapComponent';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B3M59LZ30V");


function Locations() {
  const [locationPage, setLocationPage] = useState(null);
  const [locations, setLocations] = useState(null);

  ReactGA.send({ hitType: "pageview", page: "/locations", title: "Locations" })

  useEffect(() => {
    // Fetch location page content from the API
    fetch(process.env.REACT_APP_API_URL + '/api/locationpage/')
      .then((response) => response.json())
      .then((data) => setLocationPage(data[0]));

    // Fetch locations from the API
    fetch(process.env.REACT_APP_API_URL + '/api/locations/')
      .then((response) => response.json())
      .then((data) => setLocations(data));
  }, []);


  return locationPage && locations ? (
    <Container maxWidth="md">
      <Box>
        <Typography variant="h3" component="h1" gutterBottom>
          {locationPage.title}
        </Typography>
        <Body body={locationPage.body} />

        <Grid container spacing={4} sx={{ marginTop: 4 }}>
          <LoadScript googleMapsApiKey="AIzaSyA_NM3x-nJInq36_XJKOUbbfmIX5zk0q-c">
            {locations.map((location) => {

              return (
                <Grid item xs={12} sm={6} key={location.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" component="h2" gutterBottom>
                        {location.title}
                      </Typography>
                      <Typography variant="body1" component="p" gutterBottom>
                        {location.address}
                      </Typography>
                      <Typography variant="body1" component="p" gutterBottom>
                        Phone: {location.phone}
                      </Typography>
                      <Body body={location.body} />
                    </CardContent>
                    <GoogleMapComponent address={location.address} />
                    <CardContent>
                      <Typography variant="body1" color="info.main" component={Link} href={`https://maps.google.com?q=${encodeURIComponent(location.address)}`} target="_blank" rel="noopener noreferrer" gutterBottom>
                        Google Maps
                      </Typography>
                      {' / '}
                      <Typography variant="body1" color="info.main" component={Link} href={`http://maps.apple.com/?q=${encodeURIComponent(location.address)}`} target="_blank" rel="noopener noreferrer" gutterBottom>
                        Apple Maps
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </LoadScript>
        </Grid>
      </Box>
    </Container>
  ) : (
    <div>Loading...</div>
  );
};

export default Locations;
