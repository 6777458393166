import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from './ThemeContext';

export const themeOptions = (mode) => ({
  palette: {
    mode,
    primary: {
      main: '#0206E9',
    },
    secondary: {
      main: '#FAFD2B',
    },
  },
});

const ThemeProviderWithToggle = ({ children }) => {
  const storedTheme = localStorage.getItem('themeMode') || 'light';
  const [theme, setTheme] = useState(createTheme(themeOptions(storedTheme)));

  useEffect(() => {
    setTheme(createTheme(themeOptions(storedTheme)));
  }, [storedTheme]);

  const toggleTheme = () => {
    const newMode = theme.palette.mode === 'light' ? 'dark' : 'light';
    localStorage.setItem('themeMode', newMode);
    setTheme(createTheme(themeOptions(newMode)));
  };

  return (
    <ThemeProvider value={{ theme, toggleTheme }}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeProvider>
  );
};

export default ThemeProviderWithToggle;
