import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import ThemeContext from './ThemeContext';
import Navigation from './Navigation';

const menuItems = [
  { href: '/about', text: 'About' },
  { href: '/news', text: 'News' },
  { href: '/products', text: 'Products' },
  { href: '/locations', text: 'Locations' },
  { href: '/contact', text: 'Contact' },
];

function BackgroundWrapper() {
    const { theme } = useContext(ThemeContext);
  
    return (
    <Box
        sx={{
          minHeight: '100vh',
          bgcolor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <Navigation menuItems={menuItems} />
        <Outlet />
        </Box>
  );
}

export default BackgroundWrapper;

