import React from 'react';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

const Body = ({ body }) => {
  const parsedBody = body;

  const removeDoubleQuotes = (value) => {
    if (value.startsWith('"') && value.endsWith('"')) {
      return value.slice(1, -1);
    }
    return value;
  };

  const renderBlock = (block) => {
    switch (block.type) {
      case 'heading':
        return (
          <Typography variant="h4" gutterBottom>
            {removeDoubleQuotes(block.value)}
          </Typography>
        );
      case 'paragraph':
        return (
          <Typography variant="body1" gutterBottom>
            <div dangerouslySetInnerHTML={{ __html: JSON.parse(block.value) }} />
          </Typography>
        );
      case 'image':
        return (
          <Box my={2}>
            <CardMedia
              component="img"
              image={`${process.env.REACT_APP_API_URL}/media/${block.file}`}
              alt={block.title || ''}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      {parsedBody.map((block) => (
        <React.Fragment key={block.id}>{renderBlock(block)}</React.Fragment>
      ))}
    </Box>
  );
};

export default Body;