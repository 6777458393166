import React, { useState, useEffect } from 'react';
import {
  Card,
  Typography,
  Grid,
  CardContent,
  CardMedia,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from '@mui/material';
import Body from './Body';
import ReactGA from "react-ga4";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

ReactGA.initialize("G-B3M59LZ30V");

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');

  ReactGA.send({ hitType: 'pageview', page: '/products', title: 'Products' });

  useEffect(() => {
    const fetchApi = async () => {
      const [productsRes, productTypesRes] = await Promise.all([
        fetch(process.env.REACT_APP_API_URL + '/api/products/'),
        fetch(process.env.REACT_APP_API_URL + '/api/product-types/'),
      ]);

      const [productsData, productTypesData] = await Promise.all([
        productsRes.json(),
        productTypesRes.json(),
      ]);

      setProducts(productsData);
      setProductTypes(productTypesData);
      setLoading(false);
    };

    fetchApi();
  }, []);

  const filteredProducts = products.filter(
    (product) =>
      product.title.toLowerCase().includes(search.toLowerCase()) &&
      (!filter || product.type === filter)
  );

  return loading ? (
    <div>Loading...</div>
  ) : (
    <Container maxWidth="md">
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Filter by product type</InputLabel>
              <Select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                label="Filter by product type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        {filteredProducts.map((product) => (
          <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              {product.main_image && (
                <CardMedia
                  component="img"
                  height="140"
                  image={process.env.REACT_APP_API_URL + product.main_image.url}
                  alt={product.main_image.alt || ''}
                />
              )}
              <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flexGrow: 1 }}>
                <Box>
                  <Typography gutterBottom variant="h6" component="h2">
                    {product.title}
                  </Typography>
                  <Body body={product.body} />
                </Box>
                <Box>
                  {product.price && (
                    <Typography variant="body2" color="text.secondary">
                      ${product.price}
                    </Typography>
                  )}
                  {product.in_stock ? (
                    <Box display="flex" alignItems="center" mt={1}>
                      <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                      <Typography variant="caption">In Stock</Typography>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" mt={1}>
                      <CancelIcon sx={{ color: 'red', mr: 1 }} />
                      <Typography variant="caption">Out of Stock</Typography>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductList;
