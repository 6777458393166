import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';


const GoogleMapComponent = ({ address, zoom = 15 }) => {
  const [center, setCenter] = useState(null);

  const API_KEY = 'AIzaSyA_NM3x-nJInq36_XJKOUbbfmIX5zk0q-c'

  useEffect(() => {
    const getLatLngFromAddress = async (address) => {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${API_KEY}`);
      const data = await response.json();
      console.log(data)
      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setCenter({ lat, lng });
        console.log("lat: " + lat + " lng: " + lng)
      }
    };

    getLatLngFromAddress(address);
  }, [address]);
  
  return center ? (
    <GoogleMap center={center} zoom={zoom}>
        <Box sx={{ width: '100%', height: '200px' }}>
            <Marker position={center} />
        </Box>
    </GoogleMap>
    
  ) : <div>"Loading..."</div>;
};

export default GoogleMapComponent;
