import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, List, ListItem, ListItemText, Drawer, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HillsLogo from './HillsLogo_NoText_150.png';
import ThemeToggle from './ThemeToggle';
import { Link } from 'react-router-dom';

const Navigation = ({ menuItems }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(false)} sx={{ marginRight: 'auto' }}>
        <MenuIcon />
      </IconButton>
      <List>
        {menuItems.map((item, index) => (
          <ListItem button key={index} component={ Link } to={item.href} sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const horizontalMenu = () => (
    <Box sx={{ display: 'flex' }}>
      {menuItems.map((item, index) => (
        <ListItem button key={index} component={ Link } to={item.href} sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </Box>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Link to="/">
          <img src={HillsLogo} alt="Hill's Cheese Logo" height="32" />
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 2 }}>
            Hill's Cheese
          </Typography>
          <ThemeToggle />
          {isLargeScreen ? horizontalMenu() : (
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {!isLargeScreen && (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      )}
    </>
  );
};

export default Navigation;
