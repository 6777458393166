import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Box,
  Chip,
  TextField,
  Pagination,
  Container,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B3M59LZ30V");

function News() {
  const [newsPosts, setNewsPosts] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const pageSize = 10;

  ReactGA.send({ hitType: 'pageview', page: '/news', title: 'News' });

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/api/news/')
      .then((response) => response.json())
      .then((data) => {
        const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNewsPosts(sortedData);
      });
  }, []);

  const filteredPosts = newsPosts.filter((post) =>
    post.title.toLowerCase().includes(search.toLowerCase())
  );

  const paginatedPosts = filteredPosts.slice((page - 1) * pageSize, page * pageSize);

  return (
    <Container maxWidth="md">
      <Box sx={{ marginBottom: 2 }}>
        <TextField
          fullWidth
          label="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Grid container spacing={2}>
        {paginatedPosts.map((post) => (
          <Grid item key={post.id} xs={12}>
            <Card sx={{ display: 'flex', alignItems: 'center' }}>
              <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={process.env.REACT_APP_API_URL + post.main_image.url}
                alt={post.main_image.alt}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography component={Link} to={"/news/" + post.id} gutterBottom variant="h5" color="info.main">
                {post.title} 
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {post.intro}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {post.author} - {post.date}
                </Typography>
                <Box sx={{ marginTop: 1 }}>
                  {post.categories.map((category) => (
                    <Chip key={category.id} label={category.name} sx={{ marginRight: 1, marginBottom: 1 }} />
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Pagination
          count={Math.ceil(filteredPosts.length / pageSize)}
          page={page}
          onChange={(_, value) => setPage(value)}
        />
      </Box>
    </Container>
  );
};

export default News;
