import React, { useState, useEffect } from 'react';
import { Container, Box } from '@mui/material';
import Body from './Body';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B3M59LZ30V");

const HomePage = () => {
  const [HomePage, setHomePage] = useState(null);

  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" })

  useEffect(() => {
    // Fetch Home page content from the API
    fetch(process.env.REACT_APP_API_URL + '/api/home/')
      .then((response) => response.json())
      .then((data) => setHomePage(data[0]));
  }, []);

  return HomePage ? (
    <Container maxWidth="md">
      <Box>
        <Body body={HomePage.body} />
      </Box>
    </Container>
  ) : (
    <div>Loading...</div>
  );
};

export default HomePage;
