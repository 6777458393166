import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography } from '@mui/material';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B3M59LZ30V");

function ProductDetail() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  ReactGA.send({ hitType: "pageview", page: `/products/${id}`, title: "Product Detail" })

  useEffect(() => {
    // Fetch product detail from the API
    fetch(process.env.REACT_APP_API_URL + `/api/products-detail/${id}/`)
      .then((response) => response.json())
      .then((data) => setProduct(data));
  }, [id]);

  return product ? (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description}
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <div>Loading...</div>
  );
};
export default ProductDetail;
