import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B3M59LZ30V");

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');
  const [isHuman, setIsHuman] = useState(false);
  const [errors, setErrors] = useState({});

  ReactGA.send({ hitType: 'pageview', page: '/contact', title: 'Contact' });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form input
    let inputErrors = {};
    const linkRegex = /<a\b[^>]*>(.*?)<\/a>/;
    const htmlRegex = /<[^>]*>/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!name) {
      inputErrors.name = 'Name is required';
    } else if (linkRegex.test(name) || htmlRegex.test(name)) {
      inputErrors.name = 'Name cannot contain links or HTML tags';
    }
    if (!email) {
      inputErrors.email = 'Email is required';
    } else if (!emailRegex.test(email)) {
      inputErrors.email = 'Invalid email address';
    }
    if (!body) {
      inputErrors.body = 'Message body is required';
    } else if (linkRegex.test(body) || htmlRegex.test(body)) {
      inputErrors.body = 'Message body cannot contain links or HTML tags';
    }
    if (!isHuman) {
      inputErrors.isHuman = 'Please check the box to confirm you are not a robot';
    }
    setErrors(inputErrors);

    // If there are errors, don't submit the form
    if (Object.keys(inputErrors).length > 0) {
      return;
    }

    // Submit form data
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contact/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        body,
      }),
    });

    // Handle response
    if (response.ok) {
      alert('Thanks for contacting us!');
      setName('');
      setEmail('');
      setBody('');
      setIsHuman(false);
      setErrors({});
    } else {
      alert('Sorry, there was an error submitting your message.');
    }
  };

  return (
    <Box sx={{ maxWidth: "md", mx: 'auto', mt: 4, p: 2 }}>
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>

      <FormControl fullWidth margin="normal" error={!!errors.name}>
        <InputLabel htmlFor="name">Name</InputLabel>
        <Input
          id="name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {!!errors.name && <FormHelperText>{errors.name}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth margin="normal" error={!!errors.email}>
        <InputLabel htmlFor="email">Email</InputLabel>
        <Input
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!!errors.email && <FormHelperText>{errors.email}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth margin="normal" error={!!errors.body}>
        <InputLabel htmlFor="body">Message</InputLabel>
        <TextField
          id="body"
          name="body"
          multiline
          rows={4}
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
        {!!errors.body && <FormHelperText>{errors.body}</FormHelperText>}
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            checked={isHuman}
            onChange={(e) => setIsHuman(e.target.checked)}
            color="primary"
          />
        }
        label="I'm not a robot"
        error={!!errors.isHuman}
      />
      {!!errors.isHuman && <FormHelperText>{errors.isHuman}</FormHelperText>}

      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </form>
    </Box>
  );

};

export default ContactUs;