import React, { useState, useEffect } from 'react';
import { Typography, Box, Container, Breadcrumbs, Grid, Paper } from '@mui/material';
import Body from './Body'; // Import your Body component
import { useParams } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B3M59LZ30V");

function NewsPage() {
  const router = useParams();
  const [newsPost, setNewsPost] = useState(null);

  ReactGA.send({ hitType: 'pageview', page: '/news/' + router.id, title: 'News' });

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/api/news/' + router.id)
      .then((response) => response.json())
      .then((data) => {
        setNewsPost(data);
      });
  }, [router.id]);

  if (!newsPost) {
    return <Typography>Loading...</Typography>;
  }

  // Filter out the main image from the gallery images
  const galleryImages = newsPost.gallery_images.filter(({ image }) => image.url !== newsPost.main_image.url);

  return (
    <Container sx={{ flexGrow: 1, py: 2 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link component={RouterLink} color="inherit" to="/">
          Home
        </Link>
        <Link component={RouterLink} color="inherit" to="/news/">
          News
        </Link>
        <Typography color="textPrimary">{newsPost.title}</Typography>
      </Breadcrumbs>
      <br />
      <Typography sx={{ fontWeight: 'bold', pb: 1 }} variant="h4">
        {newsPost.title}
      </Typography>
      <Typography variant="subtitle1">{new Date(newsPost.date).toLocaleDateString()}</Typography>
      <Typography sx={{ pb: 2 }} variant="body1">
        {newsPost.intro}
      </Typography>
      <img style={{ width: '100%', height: 'auto' }} src={process.env.REACT_APP_API_URL + newsPost.main_image.url} alt={newsPost.main_image.alt} />
      <Box my={4}>
        <Body body={newsPost.body} /> {/* Use the Body component */}
      </Box>
      <Grid container sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {galleryImages.map(({ image, caption }, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Paper sx={{ m: 1 }}>
              <img src={process.env.REACT_APP_API_URL + image.url} alt={image.alt} width="100%" />
              <Typography variant="caption">{caption}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {newsPost.categories.length > 0 && (
        <Box my={4}>
          {newsPost.categories.map((category) => (
            <Typography key={category.id} variant="subtitle1">
              {category.name}
            </Typography>
          ))}
        </Box>
      )}
    </Container>
  );
};

export default NewsPage;
