import React, { useState, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import Body from './Body';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B3M59LZ30V");

const AboutPage = () => {
  const [aboutPage, setAboutPage] = useState(null);

  ReactGA.send({ hitType: "pageview", page: "/about", title: "About" })

  useEffect(() => {
    // Fetch about page content from the API
    fetch(process.env.REACT_APP_API_URL + '/api/about/')
      .then((response) => response.json())
      .then((data) => setAboutPage(data[0]));
  }, []);

  return aboutPage ? (
    <Container maxWidth="md">
      <Box>
        <Typography variant="h3" component="h1" gutterBottom>
          About Us
        </Typography>
        <Body body={aboutPage.body} />
      </Box>
    </Container>
  ) : (
    <div>Loading...</div>
  );
};

export default AboutPage;
